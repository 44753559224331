<template>

  <el-container style="height:7vh;">
    <el-header
        style="background-color: #545C64; color: #fff; font-size: 20px; text-align: center; line-height: 7vh;height: 7vh;"
        class="cheader">
      <span style="color:#fff">后台管理系统</span>
      <el-button style="position: absolute;right:30px;cursor: grab;" @click="loginOut">退出</el-button>
    </el-header>

  </el-container>
  <el-container style="height: 93vh;">
    <!-- 左侧边栏 -->
    <el-aside width="200px" style="background-color: #545C64">
      <el-menu
          active-text-color="#ffd04b"
          background-color="#545c64"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          text-color="#fff"
          :router="true"
      >
        <el-menu-item :index="accountType==='admin' ? '/admin/User' :'/institution/UserI' ">
          <el-icon>
            <icon-menu/>
          </el-icon>
          <span>看护师管理</span>
        </el-menu-item>
        <el-menu-item index="/admin/patient" v-if="accountType==='admin'">
          <el-icon>
            <icon-menu/>
          </el-icon>
          <span>用户管理</span>
        </el-menu-item>
        <el-menu-item index="/admin/coupons" v-if="accountType==='admin'">
          <el-icon>
            <document/>
          </el-icon>
          <span>优惠券管理</span>
        </el-menu-item>
        <el-menu-item index="/admin/feedback" v-if="accountType==='admin'">
          <el-icon>
            <setting/>
          </el-icon>
          <span>反馈管理</span>
        </el-menu-item>
        <el-sub-menu v-if="accountType==='admin'">
          <template #title>
            <el-icon>
              <document/>
            </el-icon>
            <span>预约管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/admin/timePeriod" v-if="accountType==='admin'">时间段管理</el-menu-item>
            <el-menu-item index="/admin/appointment" v-if="accountType==='admin'">预约管理</el-menu-item>
            <el-menu-item index="/admin/appointmentstatistics" v-if="accountType==='admin'">预约统计管理</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-menu-item index="/admin/notice" v-if="accountType==='admin'">
          <el-icon>
            <document/>
          </el-icon>
          <span>通知管理</span>
        </el-menu-item>
        <el-menu-item index="/admin/institution" v-if="accountType==='admin'">
          <el-icon>
            <document/>
          </el-icon>
          <span>机构管理</span>
        </el-menu-item>
        <el-menu-item index="/admin/device" v-if="accountType==='admin'">
          <el-icon>
            <document/>
          </el-icon>
          <span>设备管理</span>
        </el-menu-item>
        <el-menu-item index="/admin/refundRecord" v-if="accountType==='admin'">
          <el-icon>
            <document/>
          </el-icon>
          <span>退款管理</span>
        </el-menu-item>
        <el-menu-item :index=" accountType==='admin' ? '/admin/scanQRCodeRecord' :'/institution/scanQRCodeRecordI' ">
          <el-icon>
            <document/>
          </el-icon>
          <span>扫码启动统计</span>
        </el-menu-item>
        <el-menu-item index="/admin/Other" v-if="accountType==='admin'">
          <el-icon>
            <document/>
          </el-icon>
          <span>其他管理</span>
        </el-menu-item>
        <el-menu-item index="/admin/admin" v-if="accountType==='admin'">
          <el-icon>
            <document/>
          </el-icon>
          <span>管理员管理</span>
        </el-menu-item>

        <!-- 更多菜单项 -->
      </el-menu>
    </el-aside>

    <!-- 主内容区 -->
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>

</template>

<script setup>
import TokenUtil from "@/utils/token";
import {useRouter} from 'vue-router';
import {
  Document,
  Menu as IconMenu,
  Location,
  Setting,
} from '@element-plus/icons-vue'

const router = useRouter(); // 获取 router 实例
const accountType = localStorage.getItem("accountType");
// token检测
if (!TokenUtil.getAccessToken()) {
  router.push({path: '/login'}); // 假设你的主页路由名称是'Home'
}
const loginOut = () => {
  TokenUtil.clearAllTokens();
  location.reload();
}

</script>

<style scoped>
.el-menu-vertical-demo {
  background-color: #545C64;
}

.cheader {
  display: flex;
  align-items: center;
}


</style>

